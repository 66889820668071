








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { UserNotificationStores } from '@/store/user_notifications';
import UserNotificationBatchForm from '@/components/user_notifications/UserNotificationBatchForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    UserNotificationBatchForm,
  },
})
export default class SendUserNotifications extends Vue {
  userNotificationStore = UserNotificationStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.userNotificationStore.createUserNotificationBatch(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user_notification/CREATE_USER_NOTIFICATION_BATCH_SUCCESS') {
        this.$router.replace('/notification_batches');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
